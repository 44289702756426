import useUIText from "../hooks/translation";
import { ReactComponent as TickIcon } from "../assets/tick-circle.svg";

const ConversationFeedback = ({ backgroundColor, color, border, onClick }) => {
  const uiText = useUIText();
	return (
		<div className="con-feedback-container">
			<div className="con-feedback-container__title">
				{uiText.conversationFeedback.title}
			</div>
			<div className="con-feedback-container__feedbacks">
				<div
					className="con-feedback-container__feedback feedback-yes"
					style={{
						border: border,
						backgroundColor: backgroundColor,
						color: color,
					}}
					onClick={onClick}
				>
					<span>{uiText.conversationFeedback.yes}</span>
					<div>
						<TickIcon />
					</div>
				</div>
				<div
					className="con-feedback-container__feedback feedback-no"
					style={{ border: border }}
				>
					<span>{uiText.conversationFeedback.no}</span>
				</div>
			</div>
		</div>
	);
};

export default ConversationFeedback;
