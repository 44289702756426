import Navbar from "./Navbar";
import Banner from "./Banner";
import FormContainer from "./FormContainer";
import { useState } from "react";

const Installer = ({
	logo,
	typeOfDemo,
	handleViewChange,
	feedbackIsAdded,
	variables,
	setFeedbackIsAdded,
}) => {
	const [resetForm, setResetForm] = useState(false);
	return (
		<div className="app">
			<Navbar
				logo={logo}
				typeOfDemo={typeOfDemo}
				handleViewChange={handleViewChange}
				onLogoClick={() => setResetForm(true)}
				mainColor={variables.mainColor}
			/>
			<Banner feedbackIsAdded={feedbackIsAdded} />
			<div className="main-container">
				<FormContainer
					resetForm={resetForm}
					setResetForm={setResetForm}
					variables={variables}
					setFeedbackIsAdded={setFeedbackIsAdded}
				/>
			</div>
		</div>
	);
};

export default Installer;
