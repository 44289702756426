import axios from "axios";

import byd from "../assets/manufacturers/byd.png";
import clemap from "../assets/manufacturers/clemap.png";
import daikin from "../assets/manufacturers/daikin.png";
import delta from "../assets/manufacturers/delta.png";
import fronius from "../assets/manufacturers/fronius.png";
import goodwee from "../assets/manufacturers/goodwee.png";
import enphase from "../assets/manufacturers/enphase.png";
import huawei from "../assets/manufacturers/huawei.png";
import sma from "../assets/manufacturers/sma.png";
import solis from "../assets/manufacturers/solis.png";
import sungrow from "../assets/manufacturers/sungrow.png";
import jasolar from "../assets/manufacturers/jasolar.png";
import jinko from "../assets/manufacturers/jinko.png";
import varta from "../assets/manufacturers/varta.png";
import solaredge from "../assets/manufacturers/solaredge.png";
import longi from "../assets/manufacturers/longi.png";
import sunsynk from "../assets/manufacturers/sunsynk.png";
import socomec from "../assets/manufacturers/socomec.png";
import tigo from "../assets/manufacturers/tigo.svg";
import foxess from "../assets/manufacturers/foxess.png";
import hypontech from "../assets/manufacturers/hypontech.jpg";
import sofarsolar from "../assets/manufacturers/sofarsolar.png";

const manufacturerLogos = {
	byd: byd,
	clemap: clemap,
	daikin: daikin,
	delta: delta,
	fronius: fronius,
	goodwe: goodwee,
	enphase: enphase,
	huawei: huawei,
	sma: sma,
	solis: solis,
	sungrow: sungrow,
	jasolar: jasolar,
	jinko: jinko,
	varta: varta,
	solaredge: solaredge,
	longi: longi,
	sunsynk: sunsynk,
	socomec: socomec,
	tigo: tigo,
	foxess: foxess,
	hypontech: hypontech,
	sofarsolar: sofarsolar,
};

export function getBrightness(color) {
	const rgb = parseInt(color.replace("#", ""), 16); // convert rrggbb to decimal
	const r = (rgb >> 16) & 0xff; // extract red
	const g = (rgb >> 8) & 0xff; // extract green
	const b = (rgb >> 0) & 0xff; // extract blue

	// Calculate brightness according to the formula: (299*R + 587*G + 114*B) / 1000
	return (299 * r + 587 * g + 114 * b) / 1000;
}

// Function to convert audio blob to base64 encoded string
export function audioBlobToBase64(blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			const arrayBuffer = reader.result;
			const base64Audio = btoa(
				new Uint8Array(arrayBuffer).reduce(
					(data, byte) => data + String.fromCharCode(byte),
					""
				)
			);
			resolve(base64Audio);
		};
		reader.onerror = reject;
		reader.readAsArrayBuffer(blob);
	});
}

// Helper function to replace underscores with spaces in labels
export function formatDeviceName(name) {
	return name.replace(/_/g, " ");
}

function unifyName(str) {
	return str.split(" ").join("");
}
export function manufacturerLogo(manufacturer) {
	return manufacturerLogos[unifyName(manufacturer).toLowerCase()];
}

export const sendQuestionData = async (
	userInput,
	answer,
	sourceDocument = "",
	sourcePages = [],
	sessionId,
	conversationId
) => {
	const instanceId = process.env.REACT_APP_INSTANCE_ID;
	const environment = process.env.REACT_APP_ENV;
	let logUrl = null;

	switch (environment) {
		case "staging":
			logUrl =
				"https://new-admin-api.service-assistant-staging.com/instance/record-question";
			break;
		case "production":
			logUrl =
				"https://new-admin-api.service-assistant.ai/instance/record-question";
			break;
		default:
			logUrl = "http://localhost:9000/instance/record-question";
			break;
	}
			if (logUrl) {
				const postData = {
					instanceId: instanceId,
					deviceName: userInput.device,
					deviceType: userInput.deviceType,
					manufacturer: userInput.manufacturer,
					question: userInput.problem,
					answer: answer,
					sourceDocument,
					sourcePages: sourcePages.join(","),
				};

				if (conversationId) {
					postData.conversationId = conversationId;
				}
				if (sessionId) {
					postData.sessionId = sessionId;
				}
				try {
					const res = await axios.post(logUrl, postData, {
						headers: {
							"Content-Type": "application/json",
						},
					});
					return {
						recordId: res.data.recordId,
						conversationId: res.data.conversationId,
					};
				} catch (err) {
					console.error("Failed to log question data:", err.message);
					return null;
				}
			}
			return null;
	};

export const sendUserFeedback = async (recordId, positive, conversationId) => {
	const environment = process.env.REACT_APP_ENV;
	let logUrl = null;

	switch (environment) {
		case "staging":
			logUrl =
				"https://new-admin-api.service-assistant-staging.com/instance/mark-record";
			break;
		case "production":
			logUrl =
				"https://new-admin-api.service-assistant.ai/instance/mark-record";
			break;

		default:
			logUrl = "http://localhost:9000/instance/mark-record";
			break;
	}
	if (logUrl) {
		const postData = {
			recordId: recordId,
			positive: positive,
		};
		if (conversationId) {
			postData.conversationId = conversationId;
		}
		try {
			await axios.post(logUrl, postData, {
				headers: {
					"Content-Type": "application/json",
				},
			});
		} catch (err) {
			console.error("Failed to log user feedback:", err.message);
		}
	}
};

export const randomId = () => {
	return String(Date.now().toString(32) + Math.random().toString(16)).replace(
		/\./g,
		""
	);
};
