import React, { useEffect, useRef } from "react";

const ExampleQuestions = ({ exampleQuestions, onClick, borderColor, cursor }) => {
    const questionRefs = useRef([]);

		useEffect(() => {
			if (questionRefs.current.length) {
				let maxHeight = 0;

				// Find the tallest question
				questionRefs.current.forEach((ref) => {
					if (ref) {
						maxHeight = Math.max(maxHeight, ref.clientHeight);
					}
				});

				// Set all questions to the height of the tallest question
				questionRefs.current.forEach((ref) => {
					if (ref) {
						ref.style.height = `${maxHeight}px`;
					}
				});
			}
		}, [exampleQuestions]);

	return (
		<div className="example-questions">
			{exampleQuestions.slice(0, 4).map((question, index) => (
				<div
					key={index + 1}
					className="example-question"
					onClick={() => onClick(question)}
					style={{
						borderColor: borderColor,
						cursor: cursor,
					}}
					ref={(el) => (questionRefs.current[index] = el)}
				>
					{question}
				</div>
			))}
		</div>
	);
};

export default ExampleQuestions;
